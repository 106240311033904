@font-face {
  font-family: 'OpenSans';
  font-weight: 500;
  src: local('OpenSans'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Caveat';
  font-weight: 500;
  src: local('Caveat'), url(./fonts/Caveat-Regular.ttf) format('truetype');
}

html {
  height: 100%;
  min-height: 940px;
  background-color: #f5f5f5;
}

body {
  height: 100%;
  min-height: 940px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Raleway', 'McLaren', 'OpenSans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'OpenSans';
  overflow: hidden;
}

#root {
  height: 100%;
}

#signator_signature_video_ident_modal_dialog {
  max-width: 800px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.moreIconButton > svg {
  height: 2rem;
  width: 2rem;
}

.bigActionButton{
  font-size: 18px;
}

.bigActionButtonIcon{
  margin-top: 0px;
  height: 18px;
}

/* custom dropdown style for lang button*/

.dropdown-toggle {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rpv-default-layout__toolbar {
  justify-content: center;
  height: 3.5rem;
}

.rpv-default-layout__sidebar-headers{
  display: none;
}

@media screen and (max-width: 800px) {
  .rpv-default-layout__toolbar{
    height: 5rem;
  }
}

input[type=radio] {
  opacity: 0;
  position: absolute;
}

.MuiStepConnector-lineVertical{
  min-height: 48px !important;
}

.modal-open {
  padding-right: 0 !important;
}

.modal-content {
  border-radius: 15px !important;
  max-height: 1080px;
  overflow-y: auto;
}

.form-control {
  display: flex !important;
}

input[type=radio]:disabled~label {
  background-color: rgb(228, 228, 228);
}

@media (max-height: 920px) {
  .modal-content {
    max-height: 600px;
    overflow-y: auto;
  }
}

@media (max-height: 650px) {
  .modal-content {
    max-height: 500px;
    overflow-y: auto;
  }
}

@media (max-height: 500px) {
  .modal-content {
    max-height: 300px;
    overflow-y: auto;
  }
}

#fsw-btn {
  right: 25px !important;
}

#rpv-default-layout__sidebar-content {
  width: 275px;
  background-color: var(--rpv-default-layout__sidebar-content--opened-background-color);
  color: var(--rpv-default-layout__sidebar-content--opened-color);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  #fsw-btn {
    bottom: 64px !important;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.rCSwtyrwf .moveable-line {
  background: #623d91 !important;
  height: 3px !important;
  box-shadow: 0 0 5px 3px rgb(41 61 102 / 30%);
}

.rCSwtyrwf .moveable-control {
  background: #623d91 !important;
  border-radius: 0 !important;
}

#signature-field:hover{
  cursor: grabbing;
}

#close{
  position: fixed;
  right: -6px;
  top: -10px;
  background-color: #8f0000;
  color: white;
  font-size: 14px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  border: 2px solid #8f0000;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#close:hover{
  background-color: white;
  color: #8f0000;
}

#close-navigationtooltip{
  z-index: 9999;
  position: fixed;
  right: 4px;
  top: -8px;
  background-color: #8f0000;
  color: white;
  font-size: 14px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  border: 2px solid #8f0000;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#close-navigationtooltip:hover{
  background-color: white;
  color: #8f0000;
}

input:checked+.slider {
  background-color: #825fb1;
}

input:focus+.slider {
  box-shadow: 0 0 1px #825fb1;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sigCanvas {
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.moveable-delete:hover {
  color: #b10000 !important;
}

.moveable-sign:hover {
  background: #17a7be !important;
}

.Signator_UploadIcon path {
  color: white;
}

.moveable-control-box {
  animation: fadeInNoHeight 0.3s linear;
}

.rpv-default-layout__body{
  background-color: #f5f5f5;
  padding-bottom: 5px;
}

.rpv-default-layout__container{
  border: none;
}

.rpv-default-layout__sidebar{
  border-right: none;
}

#rpv-default-layout__sidebar-content{
  background-color: #f5f5f5;
  border-left: none;
}

.rpv-core__textbox{
  text-align: center;
  width: 80%;
}

.rpv-core__inner-page{
  background-color: #525659;
}

.rpv-core__inner-pages {
  background-color: #525659;
  z-index: 0;
  padding-top: 24px;
}

.MuiTooltip-arrow{
  color: #1cbeda !important;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  20%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1201px) {
  .rpv-core__inner-pages{
    margin-top: 24px;
    border-radius: 16px;
    margin-right: 24px;
    height: 97% !important;
  }
}

@media screen and (max-width: 800px) {
  .rpv-core__inner-pages{
    padding-top: 55px;
  }
}

.rpv-default-layout__container{
  border-bottom: none;
  border-top: none;
}

.rpv-core__tooltip-body{
  z-index: 9999 !important;
}

.MuiStepLabel-label{
  color: #623d91 !important;
  font-family: "OpenSans" !important;
  font-weight: bold !important;
  font-size: 14px;
}

.Mui-active{
  color: #623d91 !important;
  font-family: 'OpenSans' !important;
  font-weight: bold !important;
}

.Mui-completed{
  color: #623d91 !important;
  font-family: 'OpenSans' !important;
  font-weight: bold !important;
}

#signator_text_signature_canvas{
  max-width: 100%;
  min-height: 100%;
}

.SignatorSignatureProfileCardSES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
}

.SignatorSignatureProfileCardAES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
}

.SignatorSignatureProfileCardQES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
}

.SignatorSignatureProfileCardLocal{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
}

.NoImageProfileLevelSES{
  background-color: #b9b9b9;
  color: white;
}

.NoImageProfileLevelAES{
  background-color: #1cbeda;
  color: white;
}

.NoImageProfileLevelQES{
  background-color: #623d91;
  color: white;
}

.SignatorSignatureProfileCardSES:hover{
  box-shadow: #b9b9b9 0px 3px 6px, #b9b9b9 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.SignatorSignatureProfileCardAES:hover{
  box-shadow: #1cbeda 0px 3px 6px, #1cbeda 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.SignatorSignatureProfileCardQES:hover{
  box-shadow: #623d91 0px 3px 6px, #623d91 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.SignatorSignatureProfileCardLocal:hover{
  box-shadow: #623d91 0px 3px 6px, #623d91 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.ant-modal-header{
  border-bottom: none !important;
  border-radius: 13px 13px 0 !important;
}

.ant-modal-content{
  border-radius: 13px !important;
}

.signatureProfileCardSES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
  height: 83px;
}

.signatureProfileCardAES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
  height: 83px;
}

.signatureProfileCardQES{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
  height: 83px;
}

.signatureProfileCardLocal{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
}

.signatureProfileCardSES:hover{
  box-shadow: #b9b9b9 0px 3px 6px, #b9b9b9 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.signatureProfileCardAES:hover{
  box-shadow: #1cbeda 0px 3px 6px, #1cbeda 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.signatureProfileCardQES:hover{
  box-shadow: #623d91 0px 3px 6px, #623d91 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.signatureProfileCardLocal:hover{
  box-shadow: #623d91 0px 3px 6px, #623d91 0px 3px 6px;
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.signatureProfileCardInner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.signatureProfileFrontCard {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.signatureProfileBackCard{
  transform: rotateY(180deg);
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 82px;
  font-family: 'OpenSans';
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 10px;
}

.signatureProfileCardWrapper{
  display: flex;
  height: 115px;
  flex-direction: column;
  cursor: pointer;
}

.signatureProfileCardWrapperDragging{
  display: flex;
  height: 115px;
  flex-direction: column;
  cursor: pointer;
}

/*.signatureProfileCardWrapper:hover .signatureProfileCardInner{
  transform: rotateY(180deg);
}*/

@media (max-width: 1400px) {
  .signatureProfileCardSES{
    font-size: smaller !important;
  }
  .signatureProfileCardAES{
    font-size: smaller !important;
  }
  .signatureProfileCardQES{
    font-size: smaller !important;
  }
}

@media (max-width: 1400px) {
  .SignatorSignatureProfileCardSES{
    font-size: smaller !important;
  }
  .SignatorSignatureProfileCardAES{
    font-size: smaller !important;
  }
  .SignatorSignatureProfileCardQES{
    font-size: smaller !important;
  }
}

@media (max-width: 530px) {
  .css-1gzal53 {
    border-right: none !important;
  }
}

@keyframes fadeInNoHeight {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(1px, 0, 0);
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #F2F2F2;
}

::-webkit-scrollbar-thumb {
  background: #BDBDBD;
}

::-webkit-scrollbar-thumb:hover {
  background: #6E6E6E;
}