@media (max-width: 800px) {
    .Signator_SignatureProfileSignatureDetails_Text{
        font-size: 10px !important;
    }
}

.SignatorSignatureProfileSignatureDetails{
    width: 95%;
    font-family: 'OpenSans';
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}