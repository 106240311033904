.Signator_SignButtonAnimation{
    animation: pulse 1.25s infinite;
}

.Signator_SignButtonNoAnimation:disabled {
    background-color: #cccccc !important;
}

@media screen and (max-width: 800px) {
    .Signator_SignButtonAnimation{
        margin-left: 10px !important;
        margin-right: 5px !important;
    }
    .Signator_SignButtonNoAnimation{
        margin-left: 10px !important;
        margin-right: 5px !important;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(122, 82, 182);
    }
    70% {
        -webkit-box-shadow: 0 0 0 11px rgba(94, 49, 154, 0.89);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(132, 90, 189, 0.77);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgb(122, 82, 182);
        box-shadow: 0 0 0 0 rgba(91, 44, 145, 0.58);
    }
    70% {
        -moz-box-shadow: 0 0 0 13px rgba(98, 61, 145, 0.7);
        box-shadow: 0 0 0 11px rgba(146, 101, 199, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(153, 121, 194, 0);
        box-shadow: 0 0 0 0 rgba(129, 81, 194, 0);
    }
}