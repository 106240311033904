.Toolbar {
    display: flex;
    height: 60px;
    margin: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    top: 0;
    background-color: #f5f5f5;
    z-index: 1000;
}

.Signator_ToolbarSlotMobileSettings{
    background-color: #f5f5f5;
}

.ToolbarLogo {
    height: 35px;
    margin-right: 10px;
    margin-left: 20px;
}

.Signator_ToolbarSlotsDiv{
    align-items: center;
    display: flex;
    width: 60%;
    justify-content: center;
}

.Signator_ToolbarSlotContainer{
    display: flex;
    align-items: center;
    padding-top: 5px;
}

@media screen and (max-width: 800px) {
    .Toolbar{
        flex-direction: row;
        height: 70px;
    }
    .Signator_BlankToolbarDiv{
        display: none;
    }
    .Signator_ToolbarSlotsDiv{
        width: 100%;
    }
    .Signator_ToolbarSlotContainer{
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

@media screen and (max-width: 545px) {
    .ToolbarLogo {
        height: 22px !important;
        justify-content: space-evenly;
    }

    .ToolbarLogoText {
        font-size: smaller !important;
        margin-left: 2px !important;
    }
}

.ToolbarLogoText {
    color: black;
    font-family: 'OpenSans';
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
}

.ToolbarRightMenu {
    display: flex;
    align-items: center;
}

.ToolbarMenuLanguage {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.LogoContainer {
    display: flex;
    height: 100%;
    align-items: center;
    width: 20%;
}

@media screen and (max-width: 450px) {
    .ToolbarMenuLanguage {
        margin-right: 0px;
    }
}

.LinkWithoutDecoration {
    text-decoration: none;
    cursor: pointer;
}