.signatorProfilesSidebar{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: auto;
}

.createNewSidebarContainer{
    margin-top: 45px;
}

.profilesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 75%;
}

.signatureProfileCardTop{
    display: flex;
    width: 95%;
    border-bottom: 1px solid #e3e3e3;
    align-items: center;
}

.signatureImageInProfile{
    margin-bottom: 5px;
    margin-top: 5px;
    padding-right: 5px;
}

.signatureProfileBasicDetails{
    width: 100%;
    font-family: 'OpenSans';
    padding: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e3e3e3;
}

.signatureProfileSignatureDetails{
    width: 95%;
    font-family: 'OpenSans';
    padding: 10px 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.profileNoImageContainer{
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 25px;
}

.profileNoImageInformation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: white;
    border-radius: 35px;
    font-size: 11px;
    font-weight: bold;
    color: #626262;
    border: 1px solid #afafaf;
    cursor: pointer;
}

.profileNoImageInformationChecked{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: white;
    border-radius: 35px;
    font-size: 11px;
    font-weight: bold;
    color: #626262;
    border: 1px solid #afafaf;
    box-shadow: #9a8cc2 0px 0px 5px 2px;
    cursor: pointer;
}

.profileNoImageInformation:hover{
    box-shadow: #afafaf 0px 0px 5px 2px;
}

.customRadioButtonCheckmark{
    position: relative;
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #626262;
    transition: all 0.3s;
    margin-right: 10px;
}

.customRadioButtonInput {
    position: absolute;
    opacity: 0;
}

.customRadioButtonCheckmark::after {
    content: "";
    position: absolute;
    display: none;
}

.customRadioButtonInput:checked + .customRadioButtonCheckmark::after {
    display: block;
}

.customRadioButtonCheckmark::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
}

.customRadioButtonInput:checked ~ .customRadioButtonCheckmark {
    background-color: rgb(29, 178, 203) !important;
    border: 2px solid rgb(29, 178, 203) !important;
}

.profileNoImageSign{
    background-color: rgb(28, 190, 218);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    color: white;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 38%;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #afafaf;
}

.profileNoImageSign:hover{
    background-color: rgb(29, 178, 203);
}