@font-face {
  font-family: 'OpenSans';
  font-weight: 500;
  src: local('OpenSans'), url(../fonts/OpenSans-Regular.ttf) format('truetype');
}

.errorImg{
  max-width: 50%;
  max-height: 50%;
  display: block;
  margin: auto;
}

.errorTitle{
  margin-top: 20px;
  font-size: 2vh;
  text-align: center;
  color: #623d91;
}

.LandingPageBody {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 40%;
  padding-top: 5rem;
  font-family: 'OpenSans';
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.LandingPageMockupDoc {
  background-color: white;
  padding-left: 5px;
  border-radius: 10px;
  height: 20em;
  width: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalSigningWrapper {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding-top: 2rem;
  padding-left: 0 !important;
}

.SignatureImageModalHeader {
  font-family: 'OpenSans';
  height: 40px;
  background-color: #f6f8fa;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 5px;
  font-weight: bold;
  border-bottom: 1px solid #623d91;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.ErrorModalBody {
  height: 80px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'OpenSans';
  text-align: center;
}

@keyframes fadeIn {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 35px;
    opacity: 1;
  }
}

.UploadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  color: white;
  background-color: #623d91;
  font-size: larger;
  font-family: 'OpenSans';
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  z-index: 200;
  position: relative;
  border: 1px solid;
}

.UploadButton:hover{
  background-color: #512f79;
}

.UploadButtonAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  color: white;
  background-color: #623d91;
  font-size: larger;
  font-family: 'OpenSans';
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  z-index: 200;
  position: relative;
  border: 1px solid;
  animation: pulse 1.25s infinite;
}

.UploadButtonAnimation:hover{
  background-color: #512f79;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(122, 82, 182);
  }
  70% {
    -webkit-box-shadow: 0 0 0 11px rgba(94, 49, 154, 0.89);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(132, 90, 189, 0.77);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(122, 82, 182);
    box-shadow: 0 0 0 0 rgba(91, 44, 145, 0.58);
  }
  70% {
    -moz-box-shadow: 0 0 0 13px rgba(98, 61, 145, 0.7);
    box-shadow: 0 0 0 11px rgba(146, 101, 199, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(153, 121, 194, 0);
    box-shadow: 0 0 0 0 rgba(129, 81, 194, 0);
  }
}

.LandingPageLink{
  text-align: center;
  color: #C0B1D3;
  text-decoration: none;
}

.LandingPageLink:hover{
  color: #623d91;
  text-decoration: none;
}

.bottomBanner{
  background-color: #f5f5f5;
  width: 100%;
  text-align: center;
}

.SignatureImageModalButtons {
  display: flex;
  justify-content: flex-end;
}

.SignatureImageModalButton {
  margin-right: 10px;
  background-color: #623d91;
  height: 35px;
  width: 100px;
  color: white;
  font-weight: bold;
  font-family: 'OpenSans';
  border-radius: 25px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  padding-top: 6px;
}

.Signator_IntroModal > *{
  border-radius: 10px !important;
}

.Signator_IntroModalHeader{
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-family: OpenSans;
  font-size: 20px;
  font-weight: bold;
  color: #623d91;
}

.Signator_IntroModalSubHeader{
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  color: #87818c;
}

.Signator_IntroModalComponent{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  justify-content: space-around;
  font-family: OpenSans;
  font-size: 14px;
}

@media (max-width: 450px) {
  .Signator_IntroModalComponent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Signator_IntroModalButtonYes{
    margin-bottom: 20px;
  }
}

.Signator_IntroModalButtonYes{
  background-color: #623d91;
  padding: 8px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  min-width: 120px;
  max-width: 120px;
  text-align: center;
  cursor: pointer;
}

.Signator_IntroModalButtonNo{
  background-color: white;
  border: 1px solid #623d91;
  padding: 8px;
  color: #623d91;
  font-weight: bold;
  max-width: 120px;
  border-radius: 10px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}

.Signator_IntroModalFooter{
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.34);
}

.SignatureImageModalButton:hover {
  background-color: #6f3ab4;
}

@media (max-width: 530px) {
  .LandingPageMockupDoc {
    width: 20rem;
  }
  .LandingPageBody{
    width: 80%;
  }
}


@keyframes fadeInNoHeight {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}