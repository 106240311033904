.PdfSignerOverlay {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
}

.RailGridContainer{
    padding: 88px 12px;
    grid-column: 1;
    grid-row: 3/span 5;
    background-color: rgb(237, 232, 244);
    max-width: 80px;
}

.PdfSignerViewer {
    height: 100%;
    width: 100%;
    background-color: #525659;
}

.Signator_RightSideDrawerButton{
    padding:0px 0px 0px 0px;
    text-align: center;
    margin:0px;
    width: 185px;
    height:40px;
    background: #1cbeda;
    z-index:15;
    border-radius: 5px 5px 0px 0px;
    -moz-transform:rotate(-90deg);
    -ms-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    -webkit-transform:rotate(-90deg);
    transform-origin: bottom right;
    position: fixed;
    right: 0px;
    top: 37%;
    cursor: pointer;
}

.Signator_RightSideDrawerButton p {
    color:#fff;
    display:inline-block;
    line-height:40px;
    font-weight: bold;
    font-family: OpenSans;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(122, 82, 182);
    }
    70% {
        -webkit-box-shadow: 0 0 0 11px rgba(94, 49, 154, 0.89);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(132, 90, 189, 0.77);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgb(122, 82, 182);
        box-shadow: 0 0 0 0 rgba(91, 44, 145, 0.58);
    }
    70% {
        -moz-box-shadow: 0 0 0 13px rgba(98, 61, 145, 0.7);
        box-shadow: 0 0 0 11px rgba(146, 101, 199, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(153, 121, 194, 0);
        box-shadow: 0 0 0 0 rgba(129, 81, 194, 0);
    }
}

.Signator_SignatureFieldContainer {
    animation: fadeInNoHeight 0.7s linear, pulse 1.25s infinite;
}

.Signer_Signing_Mobile_Toolbar_Option{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    justify-content: space-between;
    padding: 2px;
    color: #623d91;
    font-weight: bold;
}

.Signer_Signing_Mobile_Toolbar_Option_Icon{
    font-size: 18px;
    margin-bottom: 4px;
}

.Signator_SignWithoutImageModalButtons{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.Signator_SignWithoutImageModalButtonOne{
    height: 40px;
    font-size: 14px;
    color: #6e6e6e;
    font-family: OpenSans;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    border: 1px solid rgb(46 47 54 / 20%);
    box-shadow: rgb(0 0 0 / 27%) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    justify-content: center;
    width: 220px;
}

.Signator_SignWithoutImageModalButtonTwo{
    height: 40px;
    font-size: 14px;
    color: white;
    font-family: OpenSans;
    font-weight: bold;
    display: flex;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    background-color: #05c1e0;
    justify-content: center;
    width: 220px;
}

.Signator_SignWithoutImageModalButtonOne:hover{
    color: #89909b;
}

.Signator_SignWithoutImageModalButtonTwo:hover{
    background-color: #05aac4;
}

@media (max-width: 500px) {
    .Signator_SignWithoutImageModalButtons{
        flex-direction: column;
        align-items: center;
    }
    .Signator_SignWithoutImageModalButtonOne{
        width: 250px;
    }
    .Signator_SignWithoutImageModalButtonTwo{
        width: 250px;
        margin-top: 20px;
    }
}

@media (max-width: 540px) {
    .Signer_Signing_Mobile_Toolbar_Option {
        font-size: small;
    }
    .Signator_RightSideDrawerButton{
        width: 160px;
        font-size: 12px;
    }
}

@media (max-width: 470px) {
    .Signer_Signing_Mobile_Toolbar_Option {
        font-size: 11px;
    }
}

@media (max-width: 398px) {
    .Signer_Signing_Mobile_Toolbar_Option {
        font-size: x-small;
    }
}

@keyframes fadeInNoHeight {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}